import {
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core"
import React from "react"
import TransparentRouterLink from "../components/common/TransparentRouterLink"
import Layout from "../components/layout/Layout"
import TwoColsLayout from "../components/layout/TwoColsLayout"
import Image from "../components/simple/Image"

const Login = () => {
  return (
    <TwoColsLayout
      imageProps={{
        filename: "login_zhvcrv.jpg",
        alt: "",
      }}
    >
      <Grid item container xs={12} md={8} lg={6} spacing={4} justify="center">
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            Zaloguj się
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField label="E-mail" fullWidth />
        </Grid>

        <Grid item xs={12}>
          <TextField label="Hasło" fullWidth />
        </Grid>

        <Grid item container xs={12} justify="center">
          <Link to="/rejestracja" variant="h5">
            loguj
          </Link>
        </Grid>

        <Grid item xs={12}>
          <Typography align="center" variant="h6" gutterBottom>
            Nie masz jeszcze konta?
          </Typography>

          <Link to="/rejestracja">
            <Typography variant="h6" align="center">
              Zarejestruj się
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </TwoColsLayout>
  )
}

export default Login
